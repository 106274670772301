import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiAnalysisIdService(payload) {
  const { car_code, service } = payload;

  if (service) {
    return fetch(`properties/${car_code}/${service}`, {
      method: 'GET',
      ms: 'ANALYSIS_ESG',
      auth: true,
    });
  }

  return fetch(`properties/${car_code}`, {
    method: 'GET',
    ms: 'ANALYSIS_ESG',
    auth: true,
  });
}
