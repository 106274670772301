const COLORS = {
  '2008': '#E87722',
  '2009': '#4E137D',
  '2010': '#00D7BE',
  '2011': '#EDA778',
  '2012': '#892E64',
  '2013': '#00f400',
  '2014': '#F8B0E9',
  '2015': '#888B35',
  '2016': '#9B9DFE',
  '2017': '#FEF3AB',
  '2018': '#DE1E7A',
  '2019': '#D96B22',
  '2020': '#FF12A1',
  '2021': '#49BAE3',
  '2022': '#FFEC75',
  '2023': '#A6B9D7',
  '2024': '#6E5F72',
} as const;

const OPACITY_COLORS = {
  '2008': 'transparent',
  '2009': 'transparent',
  '2010': 'transparent',
  '2011': 'transparent',
  '2012': 'transparent',
  '2013': 'transparent',
  '2014': 'transparent',
  '2015': 'transparent',
  '2016': 'transparent',
  '2017': 'transparent',
  '2018': 'transparent',
  '2019': 'transparent',
  '2020': 'transparent',
  '2021': 'transparent',
  '2022': 'transparent',
  '2023': 'transparent',
  '2024': 'transparent',
};

export default function getStyleByYear(year: string) {
  return {
    color: COLORS[year] || 'black',
    bgOpacity: OPACITY_COLORS[year],
    fillOpacity: Number(year) >= 2019 ? 1 : 0.3,
    stroke: Number(year) >= 2019 ? false : true,
  };
}
