const ROUTES = {
  ROOT: '/analysis',
  ID: (id: string | undefined = undefined) =>
    ['/analysis', id ? `/${id}` : '/:id'].join(''),
  ID_PRINT: (id: string | undefined = undefined) =>
    ['/analysis', id ? `/${id}` : '/:id', '/print'].join(''),
  ID_VALIDATION: (id: string | undefined = undefined, service?: string) => {
    if (service) {
      return [
        '/analysis',
        id ? `/${id}` : '/:id',
        '/validation',
        `?service=${service}`,
      ].join('');
    }

    return ['/analysis', id ? `/${id}` : '/:id', '/validation'].join('');
  },
  ID_VALIDATION_PRINT: (id: string | undefined = undefined) =>
    ['/analysis', id ? `/${id}` : '/:id', '/validation', '/print'].join(''),
  REPORT: '/analysis/print/report',
};

const ANALYTICS_ROUTES = ROUTES;

export const NAMESPACE = ROUTES.ROOT;

export default ANALYTICS_ROUTES;
